import _ from 'lodash'
import React, {
  createContext, useState, useContext, useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'
import { menuData, menuMobileFooter } from '../data'

const MenuContext = createContext('')

const MenuProvaider = ({ children }) => {
  const { t } = useTranslation()
  const [menu, setMenu] = useState([])
  const [topMenu, setTopMenu] = useState([])
  const [portals, setPortals] = useState({})
  const [address, setAddress] = useState({})
  const [menuMobFooter, setMenuMobFooter] = useState([])
  const [loading, setLoading] = useState(false)

  const getLinksTopBuy = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'links',
      params: {
        loc: 'top-menu',
      },
    })
      .then((resp) => {
        const res = _.slice(resp.data, 0, 5)
        setTopMenu(res)
      })
      .catch((error) => {
        console.error(error)
      })

    return result
  }

  const getLinksAddress = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'links',
      params: {
        loc: 'footer-address',
      },
    })
      .then((resp) => {
        const res = resp.data
        setAddress(res)
      })
      .catch((error) => {
        console.error(error)
      })

    return result
  }

  const getMenuPortals = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'links',
      params: {
        loc: 'portals',
      },
    })

    return result
  }

  const getMenu = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'productCategoryMenu',
      // params: {
      //   lang: i18n.language,
      // },
    })

    return result
  }

  const getMenuMatters = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'youMatterMenu',
      // params: {
      //   lang: i18n.language,
      // },
    })

    return result
  }

  const makeMenu = async () => {
    let newMenu = []
    let menuReform = _.compact(menuData)
    setMenu([])
    setLoading(true)
    const [prodcutsResult, matterResults, portalsResults] = await Promise.all([
      getMenu(),
      getMenuMatters(),
      getMenuPortals(),
    ])
    setPortals({
      title: t('portals.title'),
      children: portalsResults.data.map((child) => ({
        title: child.text,
        url: child.link,
      })),
    })
    newMenu = _.map(menuReform, (data) => {
      let newData

      switch (data.title) {
        case 'our-products':
          data.children.splice(0, prodcutsResult.data.length)
          data.children.push(...prodcutsResult.data)
          _.uniqBy(data.children, 'id')
          newData = data
          break
        case 'matter': {
          const result = _.map(data.children, (child) => {
            if (child.title === 'experience') {
              return {
                ...child,
                children: [...matterResults.data],
              }
            }
            return child
          })
          data.children.splice(0, 2)
          data.children.push(...result)
          newData = data
          break
        }
        case 'claims': {
          const resultPortals = _.compact(_.map(data.children, (child) => {
            if (child.title === 'portal-apps') {
              return {
                ...child,
                subgroup: _.map(child.subgroup, (subgroup) => {
                  if (subgroup.title === 'portals') {
                    return {
                      ...subgroup,
                      children: portalsResults.data.map((ch) => ({
                        title: ch.text,
                        url: ch.link,
                      })),
                    }
                  }
                  return subgroup
                }),
              }
            }
            return child
          }))
          data.children.splice(0, 4)
          data.children.push(...resultPortals)
          newData = data
          break
        }

        default:
          newData = data
          break
      }
      return newData
    })
    setMenuMobFooter([...newMenu[0].children, ...menuMobileFooter])
    setMenu(newMenu)
    setLoading(false)
    menuReform = []
  }

  useEffect(() => {
    makeMenu()
    getLinksTopBuy()
    getLinksAddress()
  }, [])

  const values = {
    loading,
    getMenu,
    menuMobFooter,
    menu,
    topMenu,
    portals,
    address,
  }

  return <MenuContext.Provider value={values}>{children}</MenuContext.Provider>
}

const useMenu = () => {
  const context = useContext(MenuContext)

  if (!context) {
    throw new Error('useLocale must be used within an MenuProvaider')
  }

  return context
}

export { MenuProvaider, useMenu }
