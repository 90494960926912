import _ from 'lodash';
import enJson from './locales/en/en.json';
import thJson from './locales/th/th.json';

function getData(language) {
  const fallbackLng = {    
    // th: thJson,
    en: enJson,
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.GATSBY_TRANSLATIONS_URL}/locales/${language}/${language}.json`,
      {
        method: 'GET',
      },
    )
      .then((response) => {
        if (!response.ok) {
          console.log(`Failed to fetch translations for ${language}`);
          throw new Error(`Failed to fetch translations for ${language}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          const mergedData = _.merge(fallbackLng[language], data);
          console.log(`Successfully fetched translations for ${language}`);
          resolve(mergedData);
        } else {
          console.log(`No data received for ${language}, using fallback`);
          resolve(fallbackLng[language]);
        }
      })
      .catch((error) => {
        console.error(`Error fetching translations for ${language}:`, error);
        console.log(`Switching to local translations for ${language}`);
        resolve(fallbackLng[language]);
      });
  });
}

export default getData;
