import React from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import { getRoute } from '../../helpers'
import { withTrans } from '../../i18n/withTrans'
import './button-style.scss'

const Button = (
  {
    outline = false,
    text,
    link,
    onClick,
    fluid = false,
    state,
    big = false,
    disable = false,
    type = 'text',
    i18n,
  },
) => {
  const OnlyText = () => <span>{text}</span>

  const btnClass = classNames({
    button__container: true,
    outline,
    fluid,
    big,
  })

  const handleOnClick = () => {
    if (onClick) {
      onClick()
    } else if (link) {
      navigate(getRoute({
        lang: i18n.language,
        route: `${link}`,
      }), { state })
    } else {
      return null
    }
  }

  return (
    <button
      disabled={disable}
      onClick={handleOnClick}
      className={btnClass}
      type={type}
    >
      <OnlyText />
    </button>
  )
}

export default withTrans(Button)
