import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import classnames from 'classnames'
import { navigate } from 'gatsby'
import { useUI } from '../../hooks/use-ui'
import { useMenu } from '../../hooks/use-menu'
import IconClosed from '../../assets/icons/pacificcross-icon-close.svg'
import IconArrow from '../../assets/icons/dropdwon-arrow.svg'
import IconLanguage from '../../assets/icons/pacificcross_icons_language.svg'
import { contactMenu } from '../../data'
import './menu-mobile-styles.scss'
import SubMenuMobile from '../submenu-mobile'

const MobileMneu = ({ t }) => {
  const [subMenuSelected, setSubMenuSelected] = useState({})
  const [subSubMenuSelected, setSubSubMenuSelected] = useState({})
  const [menuMobile, setMenuMobile] = useState([])
  const { isOpenMenu, handleMenu } = useUI()
  const { menu, topMenu } = useMenu()

  const containerMenuMobile = classnames({
    mobileMenu__container: true,
    mobileMenu__container__open: isOpenMenu,
  })

  const onSelectSubMenu = (sub) => {
    // console.log('dari button ', sub)
    setSubMenuSelected(sub)
  }
  const onSelectSubSubMenu = (sub) => {
    setSubSubMenuSelected(sub)
  }

  useEffect(() => {
    setMenuMobile([...menu, contactMenu])
  }, [menu])

  const onClosedHandle = () => {
    onSelectSubMenu({})
    handleMenu()
    setSubSubMenuSelected({})
  }

  const goTo = (slug) => {
    if (slug) {
      navigate(`/${slug}/`)
      onClosedHandle()
    }
  }

  return (
    <div className={containerMenuMobile}>
      <Helmet>
        <body className={isOpenMenu ? 'is-open-menu' : ''} />
      </Helmet>
      <div className="mobileMenu__box">
        <div className="mobileMenu__close__btn">
          <IconClosed width="20" onClick={handleMenu} />
        </div>
        {/* {console.log('menu mobile ',menuMobile)} */}
        {_.map(menuMobile, (menuItem, idx) => (
          <div className="mobileMenu__row" key={idx}>
            <p>{t(`menu.${_.get(menuItem, 'title')}.title`)}</p>
            <span onClick={() => onSelectSubMenu(menuItem)}>
              {/* {console.log('menu item ',menuItem)} */}
              <IconArrow width="36" />
            </span>
          </div>
        ))}
      </div>
      <div className="mobileMenu__footer">
  {/* {console.log('menu mobile footer', topMenu)} */}
      {topMenu &&
        _.map(topMenu, (menuItem, idx) => {
          // Conditionally add new data based on some condition
          const additionalData1 = menuItem.text.toLowerCase() === 'pay medical premium' ? { 
            title: 'Pay Medical Premium', 
            slug: 'pay-medical-premium',
            children: [
              {
                // id: 'pay-in-peso',
                slug: 'pay-in-peso',
                title: 'Pay In Peso',
                link: 'https://page.portone.cloud/PCIIMedicalPaymentPHP'
              },
              {
                // id: 'pay-in-dollars',
                slug: 'pay-in-dollars',
                title: 'Pay In Dollars',
                link: 'https://page.portone.cloud/PCIIMedicalPaymentUSD'
              }
            ]
          } : {};

          const additionalData2 = menuItem.text.toLowerCase() === 'pay travel premium' ? { 
            title: 'Pay Travel Premium', 
            slug: 'pay-travel-premium',
            children: [
              {
                // id: 'pay-in-peso',
                slug: 'pay-in-peso',
                title: 'Pay In Peso',
                link: 'https://page.portone.cloud/PCIITravelPaymentPHP'
              },
              {
                // id: 'pay-in-dollars',
                slug: 'pay-in-dollars',
                title: 'Pay In Dollars',
                link: 'https://page.portone.cloud/PCIITravelPaymentUSD'
              }
            ]
          } : {};

          // Merge the original menuItem with additionalData
          const modifiedMenuItem = { ...menuItem, ...additionalData1, ...additionalData2 };

          return (
            <div key={idx} className="mobileMenu__row">
              {menuItem.text.toLowerCase() === 'pay medical premium' || menuItem.text.toLowerCase() === 'pay travel premium' ? (
                <>
                  <p>{modifiedMenuItem.text}</p>
                  <span onClick={() => onSelectSubMenu(modifiedMenuItem)}>
                    {/* {console.log('menu item custom', modifiedMenuItem)} */}
                    <IconArrow width="36" />
                  </span>
                </>
              ) : (
                <a href={modifiedMenuItem.link} target="_blank" rel="noreferrer">
                  <p>{modifiedMenuItem.text}</p>
                </a>
              )}
            </div>
          );
        })}
    </div>

      {/* <div className="mobileMenu__footer">
      {console.log('menu mobile footer',topMenu)}
        {topMenu 
          && _.map(topMenu, (menuItem, idx) => (
            <div key={idx} className="mobileMenu__row">
              <a href={menuItem.link} target="_blank" rel="noreferrer">
                <p>{menuItem.text}</p>
              </a>
            </div>
          ))}
      </div> */}
      <div className="mobileMenu__footer">
        <div className="mobileMenu__language">
          <span>
            <IconLanguage width="30" />
          </span>
          {/* <span>English</span><span>ไทย</span> */}
        </div>
      </div>
      <SubMenuMobile
        goTo={goTo}
        data={subMenuSelected}
        onSelectSubMenu={onSelectSubMenu}
        onClosedHandle={onClosedHandle}
        onSelectSubSubMenu={onSelectSubSubMenu}
        subSubMenuSelected={subSubMenuSelected}
        t={t}
      />
    </div>
  )
}

export default MobileMneu
