import _ from 'lodash'
import React from 'react'
import classnames from 'classnames'
import IconArrow from '../../assets/icons/dropdwon-arrow.svg'
import IconClosed from '../../assets/icons/pacificcross-icon-close.svg'
import SubSubMenuMobile from '../subsubmenu-mobile'
import './submenu-mobile-styles.scss'
import { navigate } from 'gatsby'

const SubMenuMobile = ({
  data,
  onSelectSubMenu,
  onClosedHandle,
  onSelectSubSubMenu,
  subSubMenuSelected,
  t,
  goTo,
}) => {
  const containerSubMenuMobile = classnames({
    mobileSubMenu__container: true,
    mobileSubMenu__container__open: !_.isEmpty(data),
  })

  const SubNabvigation = ({ child, idx }) => (
    <div key={child.title + idx} className="mobileMenu__row">
      <p onClick={child.slug ? () => goTo(child.slug) : null}>
        {data.slug === 'our-products'
          ? child.title
          : t(`menu.${data.title}.children.${child.title}.title`, {
            country: process.env.GATSBY_COUNTRY,
          })}
      </p>
      <div onClick={() => onSelectSubSubMenu(child)}>
        <IconArrow width="36" />
      </div>
    </div>
  )

  const EndNavigation = ({ child, idx }) => (
    // console.log('from end ',child),
    child.slug === 'pay-in-dollars' || child.slug === 'pay-in-peso' ? 
    <div
    className="mobileMenu__row"
    onClick={() => navigate(child.link)}
    key={child.title + idx}
  >
    {/* <p>
      {t(`menu.${data.title}.children.${child.title}.title`, {
        country: process.env.GATSBY_COUNTRY,
      })}
    </p> */}
    <p>{child.title}</p>
  </div> :  
    <div
      className="mobileMenu__row"
      onClick={() => goTo(child.slug)}
      key={child.title + idx}
    >
      <p>
        {t(`menu.${data.title}.children.${child.title}.title`, {
          country: process.env.GATSBY_COUNTRY,
        })}
      </p>
    </div>
  )

  return (
    <div className={containerSubMenuMobile}>
      <div className="mobileSubMenu__close__btn">
        <IconArrow
          width="36"
          onClick={() => onSelectSubMenu({})}
          fill="#1C75BC"
        />
        {data.slug === 'pay-medical-premium' || data.slug === 'pay-travel-premium' ? <p>{data.title}</p> : <p>{t(`menu.${data.title}.title`)}</p>}
        
        <IconClosed width="20" onClick={onClosedHandle} fill="#1C75BC" />
      </div>
      <div>
        {_.map(data.children, (child, idx) => (data.slug === 'contact' || data.slug === 'pay-medical-premium' || data.slug === 'pay-travel-premium' ? (
          // console.log('data ', child),          
          <EndNavigation key={idx} child={child} />
        ) : (
        <SubNabvigation key={idx} child={child} />
        )))}
      </div>
      <SubSubMenuMobile
        groupTitle={data.title}
        data={subSubMenuSelected}
        onSelectSubMenu={onSelectSubMenu}
        onClosedHandle={onClosedHandle}
        onSelectSubSubMenu={onSelectSubSubMenu}
        t={t}
      />
    </div>
  )
}

export default SubMenuMobile
