import _ from 'lodash'
import { Link } from 'gatsby'
import classnames from 'classnames'
import React from 'react'
import { getRoute } from '../../helpers'
import { contactMenu } from '../../data'
import './menu-styles.scss'

const Item = ({
  selectedMenuFirstLevel,
  item,
  onSelectMenu,
  t,
  idx,
  isOpenSubMenu,
}) => {
  const classItemMenu = classnames({
    menu__item: true,
    menu__item__active: isOpenSubMenu && selectedMenuFirstLevel === item.slug,
  })
  // {console.log('from menus', t(`menu.${item.title}.title`))}
  // {console.log('from menus 2', item.title)}
  // {console.log("apa ",t('home.matter.title'))}
  return (    
    <li
      className={classItemMenu}
      onMouseEnter={() => onSelectMenu(item.slug, idx)}
    >
      <p>
        <span>{t(`menu.${item.title}.title`)}</span>
      </p>
    </li>
  )
}

const Menu = ({
  t,
  i18n,
  handleMouseEnter,
  handleMouseLeave,
  selectMenu,
  selectMenuContact,
  menu,
  isOpenContactMenu,
  selectedMenuFirstLevel,
  setSelectedMenuFirstLevel,
  isOpenSubMenu,
}) => {
  const onSelectMenu = (slug, idx) => {
    selectMenu(idx)
    setSelectedMenuFirstLevel(slug)
  }
  return (
    <div className="menu__container row">
      <div className="col-10">
        <ul
          className="menu__list"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {_.map(menu, (item, idx) => (
            <Item
              key={item.title}
              item={item}
              selectedMenuFirstLevel={selectedMenuFirstLevel}
              onSelectMenu={onSelectMenu}
              t={t}
              idx={idx}
              isOpenSubMenu={isOpenSubMenu}
            />
          ))}
        </ul>
      </div>
      <div
        className="col-2 menu__contact"
        onMouseEnter={() => selectMenuContact(true)}
        onMouseLeave={() => selectMenuContact(false)}
      >
        <ul className="row">
          <li className="col-12 menu__item">
            <p>
              <span>{t('menu.contact.title')}</span>
            </p>
          </li>
        </ul>
        {isOpenContactMenu && (
          <div className="menu__submenu__contact">
            <div className="menu__submenu__contact__box">
              <ul>
                {_.map(_.compact(contactMenu.children), (child) => (
                  <li key={child.slug}>
                    <Link to={getRoute({
                      lang: i18n.language,
                      route: `/${child.slug}/`,
                    })}
                    >
                      <span key={child.title}>
                        {t(
                          `menu.contact.children.contact-with-us.children.${child.title}`,
                        )}
                      </span>
                      {/* <span className="arrow-right"><ArrowRight width="20" fill="#FF9124"/></span> */}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Menu
