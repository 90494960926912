import _ from 'lodash'
import { Link } from 'gatsby'
import React from 'react'
import classNames from 'classnames'
import { withTrans } from '../../../i18n/withTrans'
import { getRoute } from '../../../helpers'
import ArrowRight from '../../../assets/icons/pacificcross_icons_arrow-right.svg'
import './sub-menu-styles.scss'

const ListWithoutSubgroup = ({
  m,
  t,
  titleMenuSelected,
  submenutitle,
  menuSelectedItem,
  id,
  parentSlug,
  i18n,
}) => {
  const columnClass = classNames({
    column: true,
    long__list: m.children && m.children.length > 7,
  })
  return (
    <div className="row submenu__children">
      <div className="col">
        <ul className={columnClass}>
          {m.children
            && _.map(_.compact(m.children), (child, idx) => {
              const slug = parentSlug ? `/${parentSlug}/${child.slug}/` : `/${child.slug}/`
              return (
                <li key={idx}>
                  <Link to={getRoute({
                    lang: i18n.language,
                    route: slug,
                  })}>
                    <span key={child.title}>
                      {menuSelectedItem === 0
                      || id === 'our-products'
                      || id === 'matter'
                        ? _.get(child, 'title', child.name)
                        : t(
                          `menu.${titleMenuSelected}.children.${submenutitle}.children.${child.title}`,
                        )}
                    </span>
                    <span className="arrow-right">
                      <ArrowRight width="20" fill="#FF9124" />
                    </span>
                  </Link>
                </li>

              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default withTrans(ListWithoutSubgroup)
