import _ from 'lodash'
import Helmet from 'react-helmet'
import React, { useState, useEffect } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { withTrans } from '../../i18n/withTrans'
import Header from '../header'
import Footer from '../footer'
import Spinner from '../Spinner'
import { setCallback } from '../../i18n/config'
import MenuMobile from '../menu-mobile'
import FooterMobile from '../footer/footer-mobile'
import './layout-styles.scss'

const Layout = ({
  children, t, i18n, location,
}) => {
  const language = process.env.GATSBY_AVAILABLE_LOCALES
  const arrayOflang = language.split(' ')
  const breakpoints = useBreakpoint()
  const [isOpenMenu] = useState(false)
  const [loading, setLoading] = useState(true)
  const socialmediafake = {
    facebook: 'https://www.facebook.com/',
    instagram: 'https://instagram.com/',
    linkedin: 'https://www.linkedin.com/',
    youtube: 'https://www.youtube.com/',
  }

  const socialMediaObject = _.reduce(
    socialmediafake,
    (result, value, key) => {
      result.push({
        name: key,
        link: value,
        size: 22,
        color: '#1C75BC',
      })

      return result
    },
    [],
  )

  useEffect(() => {
    _.map(arrayOflang, (lng) => (_.includes(location.pathname, lng) ? i18n.changeLanguage(lng) : i18n.changeLanguage('en')))
  }, [location])

  if (loading) {
    setCallback(() => setLoading(false))
    return <Spinner />
  }

  return (
    <div className="global-wrapper layout__container">
      <Helmet>
        <body className={isOpenMenu ? 'is-open-menu' : ''} />
      </Helmet>
      <Header location={location}/>
      <div className="children">{children}</div>
      <MenuMobile t={t} />
      {!breakpoints.sm && (
        <Footer t={t} i18n={i18n} socialmedia={socialMediaObject} />
      )}
      {breakpoints.sm && (
        <FooterMobile t={t} i18n={i18n} socialmedia={socialMediaObject} />
      )}
    </div>
  )
}

export default withTrans(Layout)
