import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { getRoute } from '../../helpers'
import { menuFooter } from '../../data'
import { useMenu } from '../../hooks/use-menu'
import FooterColumn from './components/footer-column'
import FooterLogoColumn from './components/footer-logo-column'
import './footer-styles.scss'

const Footer = ({ t, i18n, socialmedia }) => {
  const [finalMenu, setFinalMenu] = useState([])
  const [productTitles, setProductTitles] = useState([])
  const { menu, portals, address } = useMenu()
  useEffect(() => {
    const newMenuFooter = [...menuFooter]
    let newMenu = []
    if (menu.length > 0 && _.isEmpty(newMenu)) {
      const products = _.head(_.filter(menu, { slug: 'our-products' }))
      const productsTail = products.children.slice(3, 5)

      const productHead = products.children.slice(0, 3)
      setProductTitles(_.map(products.children, (item) => item.title))

      newMenu = _.map(newMenuFooter, (item, idx) => {
        let columnArrray
        switch (idx) {
          case 1:
            columnArrray = [...productsTail, ...item]
            break
          case 2:
            columnArrray = item.map((obj, i) => (obj.title === 'portals' ? portals : item[i]))
            break

          default:
            columnArrray = [...productHead]
            break
        }
        return columnArrray
      })
      setFinalMenu(newMenu)
    }
  }, [menu])

  return (
    <div className="container-fluid footer__container">
      <div className="container-xxl">
        <div className="row">
          <FooterLogoColumn t={t} socialmedia={socialmedia} address={address} />
          {finalMenu
            && _.map(finalMenu, (menuColumn, idx) => (
              <div className="col" key={idx}>
                <FooterColumn menu={menuColumn} productTitles={productTitles} />
              </div>
            ))}
        </div>
        <div className="d-flex flex-row footer__bottom justify-content-between">
          <div>
            <span>
              {' '}
              {t('footer.bottom-menu.rights', {
                year: new Date().getFullYear(),
              })}
            </span>
          </div>
          <div>
            <ul className="d-flex flex-row">
              {process.env.GATSBY_HAS_EMPLOYEES === 'true' ? (
                <li>
                  <Link to={getRoute({
                    lang: i18n.language,
                    route: '/employee/',
                  })}>
                    <span>{t('footer.bottom-menu.employee')}</span>
                    {' |'}
                  </Link>
                </li>
              ) : null}
              <li>
                <Link to={getRoute({
                  lang: i18n.language,
                  route: '/sitemap/',
                })}>
                  <span>{t('footer.bottom-menu.sitemap')}</span>
                  {' |'}
                </Link>
              </li>
              <li>
                <Link to={getRoute({
                  lang: i18n.language,
                  route: '/privacy-policy/',
                })}>
                  <span>{t('footer.bottom-menu.privacy')}</span>
                  {' |'}
                </Link>
              </li>
              <li>
                <Link to={getRoute({
                  lang: i18n.language,
                  route: '/terms-and-conditions/',
                })}>
                  <span>{t('footer.bottom-menu.terms-conditions')}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
